<!--
    @name: index
    @description：index
    @author: ZengWei
    @date: 2021-12-16 17:05
-->
<template>
	<div
		class="form-data-list"
		v-loading="loading"
	>
		<div class="header">
			<h5 class="form-title">{{moduleName}}</h5>
			<div class="action-bar">
				<el-tooltip
					class="item"
					effect="dark"
					content="刷新"
					placement="bottom"
				>
					<i
						class="el-icon-refresh"
						@click="searchRest"
					></i>
				</el-tooltip>
				<el-tooltip
					class="item"
					effect="dark"
					:content="showSearch?'收起搜索':'展开搜索'"
					placement="bottom"
				>
					<i
						v-if="showSearch"
						class="el-icon-arrow-down"
						@click="showSearch=false"
					></i>
					<i
						v-else
						class="el-icon-arrow-up"
						@click="showSearch=true"
					></i>
				</el-tooltip>
				<!--<el-tooltip class="item" effect="dark" content="数据导出" placement="bottom">
					<i class="el-icon-position"></i>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="数据查询器" placement="bottom">
					<i class="el-icon-search"></i>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="数据模板" placement="bottom">
					<i class="el-icon-receiving"></i>
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="批量删除" placement="bottom">
					<i class="el-icon-delete"></i>
				</el-tooltip>-->
				<el-button
					type="primary"
					size="small"
					icon="el-icon-search"
					style="margin-left: 10px"
					@click="openAddModal"
				>新增
				</el-button>
			</div>
		</div>
		<template v-if="searchComp && searchComp.length">
      <div class="search-container">
        <div
          v-show="showSearch"
          class="search"
        >
          <div class="search-comp">
            <el-form
              label-position="top"
              size="small"
              label-width="80px"
            >
              <el-row :gutter="20">
                <el-col
                  :span="6"
                  v-for="(item,index) in searchComp"
                  :key="item.config.__config__.formId"
                >
                  <el-form-item :label="item.config.__config__.label">
                    <inputItem
                      :nodeElement="item"
                      :key="index"
                      :firstIndex="index"
                    >
                    </inputItem>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="search-btn">
            <el-button
              size="small"
              @click="searchRest"
            >重置</el-button>
            <el-button
              size="small"
              type="primary"
              @click="searchAction"
            >查询</el-button>
          </div>
        </div>
      </div>
		</template>

		<div
			ref="tableWrapper"
			class="table-list"
		>
			<el-table
				:data="tableData"
				border
				height="100%"
				style="width: 100%;"
			>

				<el-table-column
					align="center"
					type="selection"
					width="55"
				>
				</el-table-column>

				<el-table-column
					v-for="item in columnList"
					:key="item.key"
					:label="item.title"
					:width="item.width"
				>
					<template slot-scope="scope">
						<item-text
							v-if="formValidate"
							:column-value="scope.row[item.key]"
							:column-config="columnConfig(item.key)"
						></item-text>
					</template>
				</el-table-column>

				<el-table-column
					label="操作"
					width="160"
					align="center"
				>
					<template slot-scope="scope">
						<span
							class="operation-primary"
							@click="openDetailModal(scope.row)"
						>详情</span>
						<span
							class="operation-primary"
							@click="openEditModal(scope.row)"
						>编辑</span>
						<span
							class="operation-del"
							@click="confirmDel(scope.row)"
						>删除</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination">
			<el-pagination
				background
				layout="prev, pager, next"
				:page-size="15"
				:current-page="page"
				:total="total"
				@current-change="changePage"
			>
			</el-pagination>
		</div>

		<el-dialog
			:title="moduleName+ formModeTitle[formMode]"
			:visible.sync="dialogVisible"
			width="60%"
			custom-class="vertical-center"
		>
			<div
				v-if="['add','edit'].includes(formMode)"
				class="scroller wrapper"
			>
				<Store
					v-if="dialogVisible && formDesignData"
					:form-data="formDesignData"
					:module-uuid="moduleUuid"
					:data-id="dataId"
					@on-submit="handleSubmit"
					@on-cancel="handleCancel"
					:formFillData="formFillData"
				>
				</Store>
			</div>
			<div
				v-else
				class="scroller wrapper"
			>
				<Detail
					v-if="dialogVisible && formDesignData"
					:form-data="formDesignData"
					:data-id="dataId"
					:module-uuid="moduleUuid"
					:formFillData="formFillData"
				>
				</Detail>
			</div>
			<!--<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>-->
		</el-dialog>
	</div>
</template>

<script>
/* eslint-disable */
	import {formDesign} from "@/api/home";
	import parser from "@/components/formParser/parser_pc";
	import skeleton from "@/components/formParser/skeleton";
	import ItemText from "./components/ItemText";
	import InputItem from "../input_item";
	import Store from "./components/Store";
	import Detail from "./components/Detail";

export default {
	name: 'index',
	components: {
		ItemText,
		InputItem,
		Store,
		Detail,
	},
	props: {
		// 绑定的表单UUID
		moduleUuid: {
			type: String,
			default: 'formModule61c6b278ee6c1' //formModule61bd73d7a07ab
		},
		// 表单填充数据
		formFillData: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			showSearch: false,
			tableData: [],

			loading: true,
			dialogVisible: false,
			formMode: 'add',
			formModeTitle: {
				add: '新增',
				edit: '修改',
				detail: '详情'
			},
			dataId: 0,
			moduleName: '表单列表数据',
			formDesignData: null,
			searchColumn: [],
			columnList: [],
			fieldsList: [],
			formValidate: null,

			page: 1,
			total: 0
		};
	},
	created() {
		this.formValidateData();
		this.initFormData();
	},
	computed: {
		columnConfig() {
			return function (key) {
				if (this.formValidate && this.formValidate.items) {
					const config = this.formValidate.items.filter(
						(item) => item.field === key
					);
					const configData = config[0] ? config[0].config : {};
					return configData;
				}
				return {};
			};
		},
		searchComp() {
			if (this.formValidate && this.formValidate.items) {
				let components = [];
				const searchList = Array.from(this.searchColumn, (item) => item.key);

				for (let item of this.formValidate.items) {
					if (searchList.includes(item.field)) {
						components.push(item);
					}
					if (item.value instanceof Array) {
						for (let itemValue of item.value) {
							if (searchList.includes(itemValue.field)) {
								components.push(itemValue);
							}
							/*for (let ele of itemValue){
									if(searchList.includes(item.field)){
										components.push(ele)
									}
								}*/
						}
					}
				}
				components = JSON.parse(JSON.stringify(components));
				return components;
			}
			return null;
		}
	},
	methods: {
		initFormData() {
			let param = { module_uuid: this.moduleUuid };
			formDesign.formDataList(param).then((res) => {
				if (res.data.code === 200) {
					const formDesign = res.data.data.design;
					let designData = JSON.parse(formDesign.json);
					if(designData){
						designData.need_flow = !!formDesign.need_flow;
						this.formDesignData = designData;
          } else {
						this.$message.error('表单设计为空,请配置表单后使用');
          }
					this.moduleName = formDesign.module_name;
					this.columnList = res.data.data.columns;
					this.searchColumn = res.data.data.searchs;
					this.tableData = res.data.data.data;

					this.page = res.data.data.current_page;
					this.total = res.data.data.total;

					this.loading = false;
				}
			});
		},
		formValidateData() {
			let param = { module_uuid: this.moduleUuid };
			formDesign.formValidate(param).then((res) => {
				if (res.data.code === 200) {
					this.formValidate = res.data.data;
				}
			});
		},
		openAddModal() {
			this.dialogVisible = true;
			this.formMode = 'add';
			this.dataId = 0;
		},
		openEditModal(row) {
			this.dialogVisible = true;
			this.formMode = 'edit';
			this.dataId = row.id;
		},
		openDetailModal(row) {
			this.dialogVisible = true;
			this.formMode = 'detail';
			this.dataId = row.id;
		},
		confirmDel(row) {
			this.$confirm('请确认是否要删除此条数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.loading = true;
					formDesign.formDataDel(row.id).then((res) => {
						if (res.data.code === 200) {
							this.$message.success(res.data.msg);
							this.searchData();
						} else {
							this.$message.success(res.data.msg);
						}
						this.loading = false;
					});
				})
				.catch(() => {});
		},
		handleCancel() {
			this.dialogVisible = false;
		},
		handleSubmit(formSubmitData, dataId) {
			formSubmitData.module_uuid = this.moduleUuid;
			if (dataId) {
				formDesign.formDataUpdate(formSubmitData, dataId).then((res) => {
					if (res.data.code === 200) {
						this.$message.success(res.data.msg);
						this.searchData();
						this.dialogVisible = false;
					} else {
						this.$message.success(res.data.msg);
					}
				});
			} else {
				formDesign.formDataStore(formSubmitData).then((res) => {
					if (res.data.code === 200) {
						this.$message.success(res.data.msg);
						this.searchData();
						this.dialogVisible = false;
					} else {
						this.$message.success(res.data.msg);
					}
				});
			}
		},
		changePage(page) {
			this.searchData({ page });
		},
		searchData(param = {}) {
			param.module_uuid = this.moduleUuid;
			formDesign.formDataList(param).then((res) => {
				if (res.data.code === 200) {
					this.tableData = res.data.data.data;

					this.page = res.data.data.current_page;
					this.total = res.data.data.total;
				}
			});
		},
		searchRest() {
			this.searchData();
		},
		searchAction() {
			const searches = this.searchComp;
			let formSubmitData = {};
			if (searches && searches instanceof Array) {
				for (let item of searches) {
					if (item.value) {
						formSubmitData[item.field] = item.value;
					}
				}
			}
			const keys = Object.keys(formSubmitData);
			if (keys.length > 0) {
				let params = { search: formSubmitData };
				this.searchData(params);
			}
			this.showSearch = false;
		}
	}
};
</script>

<style lang="less" scoped>
	.form-data-list {
		position: relative;

		.wrapper {
			max-height: 90vh;
			overflow-x: hidden;
			overflow-y: auto;
			padding-right: 10px;
		}

		.header {
			display: flex;
			align-items: center;
			height: 56px;
			padding: 0 15px;
			border-bottom: 1px solid #ebeef5;

			.form-title {
				width: 70%;
			}

			.action-bar {
				flex: 1;
				text-align: right;

				i {
					padding: 10px;
				}
			}

		}
    .search-container{
      position: absolute;
      z-index: 10;
      width: 100%;
      background-color: #ffffff;
      box-shadow:-5px 5px 10px -4px #ededed,5px 5px 10px -4px #ededed;
      .search {
        position: relative;
        margin-top: 10px;
        padding: 0 15px 10px;
        border-bottom: 1px solid #ebeef5;

        :deep(.el-select){
          width: 100%;
        }

        .search-btn {
          position: absolute;
          bottom: 20px;
          right: 15px;
        }

        :deep(.search-comp) {
          padding-right: 170px;

          .el-form-item {
            margin-bottom: 10px !important;
          }

          .el-form-item__label {
            line-height: 26px;
            padding: 0;
          }
        }
      }
    }

		.table-list {
			margin-top: 15px;
			padding: 0 15px;
      height: calc(100vh - 265px);

			.operation-primary{
				padding: 0 5px;
				color: #0076ff;
				position: relative;
				cursor: pointer;
				font-size: 12px;
			}

			.operation-primary:not(:last-child):after {
				content: "";
				height: 12px;
				width: 1px;
				background-color: #c2c5cc;
				position: absolute;
				top: 3px;
				right: 0;
			}

			.operation-del{
				font-size: 12px;
        padding-left: 8px;
        color: red;
        cursor: pointer;
			}
		}

		.pagination {
      margin-top: 10px;
      margin-right: 5px;
      text-align: right;
		}
	}
</style>
