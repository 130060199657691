<!--
    @name: Column
    @description：Column
    @author: ZengWei
    @date: 2021-12-17 14:16
-->
<template>
	<div>
		<template v-if="textType === 'textarea'">
			<el-tooltip class="item" effect="dark" :content="displayText" placement="top">
				<span>{{displayText.substr(0,10)+'...'}}</span>
			</el-tooltip>
		</template>
		<template v-else-if="textType === 'upload'">
			<el-avatar
				v-for="(item,index) in uploadFiles"
        :key="index"
				shape="square"
        :size="80"
				:src="fileImg(item.fileext,item.path)"></el-avatar>
		</template>
    <template v-else-if="textType === 'qrCode'">
      <el-avatar :size="80" shape="square" :src="displayText"></el-avatar>
    </template>
		<span v-else>{{displayText}}</span>
	</div>
</template>

<script>
/* eslint-disable */
	// 文件图标
	const pdf = '//static.bimcc.com/images/disk/pdf.png';
	const ppt = '//static.bimcc.com/images/disk/ppt.png';
	const word = '//static.bimcc.com/images/disk/word.png';
	const excel = '//static.bimcc.com/images/disk/excel.png';
	const none = '//static.bimcc.com/images/disk/none.png';
	import { baseUrl } from '@/libs/util';
	export default {
		name: "ItemText",
		components: {},
		props: ['columnValue','columnConfig'],
		data() {
			return {
				baseUrl:baseUrl(),
				displayText:'',
				textType:'',
				uploadFiles:[],
				fileIcon: {
					pdf:pdf, ppt:ppt, pptx:ppt,
					doc:word, docx:word, xls:excel,
					xlsx:excel, none:none
				}
			}
		},
		created() {
			this.initData()
		},
		computed:{
			fileImg(){
				return function (ext,path) {
					if(['png','jgp','gif','jpeg'].includes(ext)){
						return this.baseUrl + path
					} else if(this.fileIcon[ext]){
						return this.fileIcon[ext]
					} else {
						return this.fileIcon.none
					}
				}
			},
		},
		mounted() {

		},
		methods: {
			initData(){
				if(this.columnConfig.__config__){
					const tagIcon = this.columnConfig.__config__.tagIcon;
					this.textType = tagIcon;
					switch (tagIcon) {
						case 'switch':
							this.displayText = (this.columnValue == 0)? '否' : '是';
							break
						case 'checkbox':
							const value = JSON.parse(this.columnValue)
							if(value instanceof Array){
								let options = this.columnConfig.__slot__.options;
								let str = '';
								for (let item of options){
									let optVal = item.value+'';
									if(value.includes(optVal)){
										str += item.label + ',';
									}
								}
								this.displayText = str.substr(0,str.length-1)
							}
							break;
						case 'select':
						case 'radio':
							const options = this.columnConfig.__slot__.options;
							const goal = options.filter(item=>item.value == this.columnValue)
							this.displayText = goal[0].label || this.columnValue;
							break;
						case 'cascader':
						case 'treeSelect':
							let opts = [];
							tagIcon === 'cascader'
								? opts = this.columnConfig.options
								: opts = this.columnConfig.__slot__.options;

							let optStr = '';
							const colVal = JSON.parse(JSON.stringify(this.columnValue));
							let tempId = []; //去重，存在重复ID数据相互绑定
							function mapTree (data) {
								const haveChildren = Array.isArray(data.children) && data.children.length > 0;
								if(colVal instanceof Array && colVal.findIndex(item=>item == data.value)>-1){
									if(!tempId.includes(data.value)){
										tempId.push(data.value)
										optStr += data.label + ',';
									}
								} else if(colVal == data.value){
									if(!tempId.includes(data.value)){
										tempId.push(data.value)
										optStr += data.label + ',';
									}
								}
								if(haveChildren){
									data.children.map(i => mapTree(i))
								}
							}
							opts.map(i => mapTree(i))
							this.displayText = optStr.substr(0,optStr.length-1)
							break;
						case 'upload':
							this.uploadFiles = JSON.parse(this.columnValue)
							break;
						case 'user':
							const name = Array.from(this.columnValue,item=>item.name);
							this.displayText = name.join('，');
							break;
						case 'time-range':
							let timeRange = this.columnValue;
							if(timeRange[0] && timeRange[1]){
								let startStr = new Date(timeRange[0]).toLocaleTimeString()
								let endStr = new Date(timeRange[1]).toLocaleTimeString()
								this.displayText = startStr+ ' / ' + endStr
							}
							break;
						case 'date':
							this.displayText = this.columnValue.substr(0,10)
							break;
						case 'date-range':
							const date = JSON.parse(this.columnValue)
							if(date[0] && date[1]){
								this.displayText = date[0].substr(0,10)+ ' / ' + date[1].substr(0,10)
							}
							break;
						default:
							this.displayText = this.columnValue
					}
				}else{
					this.displayText = this.columnValue
				}

			},
		},
	}
</script>

<style scoped>

</style>
