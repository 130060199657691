<!--
    @name: store
    @description：store
    @author: ZengWei
    @date: 2021-12-16 17:06
-->
<template>
	<div>
		<parser
			v-if="!dataId && formData"
			ref="formParser"
			:form-data="formData"
			:need-flow="!!formData.need_flow"
			:module-uuid="moduleUuid"
			form-mode="add"
			:hide-btn="false"
			@submit="handleSubmit"
			@on-cancel="handleCancel"
		>
		</parser>

		<parser
			v-else-if="dataId && formData && editData"
			ref="formParser"
			:form-data="formData"
			:hide-btn="false"
			:displayData="editData"
			@submit="handleSubmit"
		>
		</parser>

		<skeleton v-else></skeleton>
	</div>
</template>

<script>
	import {formDesign} from "@/api/home";
	import parser from "@/components/formParser/parser_pc";
	import skeleton from "@/components/formParser/skeleton";
	export default {
		name: "store",
		components: {parser,skeleton},
		props: ['moduleUuid','formData','dataId'],
		data() {
			return {
				editData: null,
			}
		},
		mounted() {
			if(this.dataId) this.getDetailData();
		},
		methods: {
			handleSubmit(formSubmitData){
				this.$emit('on-submit',formSubmitData,this.dataId)
			},
			handleCancel(){
				this.$emit('on-cancel')
			},
			getDetailData(){
				formDesign.formDataDetail(this.dataId).then(res=>{
					if(res.data.code === 200){
						this.editData = res.data.data;
					}
				});
			}
		},
	}
</script>

<style scoped>

</style>
